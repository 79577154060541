import React, {useState} from "react";
import {connect, useDispatch} from "react-redux";
import {API_URL, TOKEN} from "../../utils/consts";
import axiosInstance from "../../utils/apiCalls";
import {updateToken} from "../../storage/actions/UserActions";

function TokenScreen() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const userId = url.searchParams.get('userId');

    const login = () => {
        setIsLoading(true);
        setError(null);

        axiosInstance.get(API_URL + '/users/token-by-token', {
            params: {
                token,
                userId
            }
        })
            .then(response => response.data)
            .then(responseJson => {
                const credentials = {
                    id: responseJson.data.token.user_id,
                    token: responseJson.data.token.code,
                    username: responseJson.data.username,
                    roles: responseJson.data.roles,
                };
                dispatch(updateToken(credentials));
                localStorage.setItem(TOKEN, JSON.stringify(credentials));
                window.location.href = '/';
            }).catch(errorResponse => {
                setError('Ошибка при подтверждении токена, проверьте правильность ссылки или обратитесь к администратору');
                console.log(errorResponse);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className="p-5 mb-4 bg-light rounded-3">
            <div className="container-fluid py-5">
                <h1 className="display-5 fw-bold">Подтвердить переключение пользователя</h1>
                <div>
                    {isLoading ? (
                        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        <a className="btn btn-primary btn-lg" onClick={login}>Подтвердить</a>
                    )}
                </div>
                {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
            </div>
        </div>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TokenScreen);
