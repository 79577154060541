import axios from 'axios';
import { API_URL, TOKEN } from './consts';
import { useNavigate } from 'react-router-dom';

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'content-type': 'application/json',
        'x-api-key': 'secret-key',
    },
});

export const axiosInstanceWithoutToken = axios.create({
    baseURL: API_URL,
    headers: {
        'content-type': 'application/json',
        'x-api-key': 'secret-key',
    },
});

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            const parsedToken = JSON.parse(token);
            config.headers['Authorization'] = `Bearer ${parsedToken.token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        console.log(error);
        if (error.response && error.response.status === 401) {
            localStorage.removeItem(TOKEN);

            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
