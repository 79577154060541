import React, {memo, useState} from "react";
import {Controller} from "react-hook-form";
import Select from "react-select";

const SelectWithFormHook = ({
                                control,
                                attribute,
                                options,
                                label,
                                isRequired,
                                requiredMessage,
                                placeholder,
                                value,
                                setValue,
                                errorMessage,
                                onChangeCallback,
                                isVisible = false,
                                readOnly = false,
                                disabled = false
                            }) => {

    const [customValue, setCustomValue] = useState(value);

    if (!isVisible) {
        return false;
    }


    return (
        <div className="col">
            <label htmlFor="projectId" className="form-label">{label}</label>
            <Controller
                control={control}
                defaultValue={value}
                name={attribute}
                disabled={disabled}
                rules={{required: isRequired ? requiredMessage : isRequired}}
                render={({field: {onChange}, formState, fieldState}) => {
                    return (<Select
                        defaultValue={value}
                        value={customValue}
                        isDisabled={disabled}
                        placeholder={placeholder}
                        className={errorMessage ? 'is-invalid' : ''}
                        onChange={(newValue) => {
                            setValue(newValue)
                            setCustomValue(newValue)
                            onChange(newValue.value)
                            onChangeCallback(newValue);
                        }}
                        options={options}/>)
                }}
            />
            <div className="invalid-feedback">{errorMessage}</div>
        </div>
    );
};

export default memo(SelectWithFormHook);
