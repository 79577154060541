export const getColClass = (col) => {
    let classes = [];

    if (col.col) {
        classes.push('col-' + col.col);
    }
    if (col.col_offset) {
        classes.push('col-offset-' + col.col_offset);
    }
    if (col.col_xs) {
        classes.push('col-xs-' + col.col_xs);
    }
    if (col.col_xs_offset) {
        classes.push('offset-xs-' + col.col_xs_offset);
    }

    if (col.col_sm) {
        classes.push('col-sm-' + col.col_sm);
    }

    if (col.col_sm_offset) {
        classes.push('offset-sm-' + col.col_sm_offset);
    }

    if (col.col_md) {
        classes.push('col-md-' + col.col_md);
    }

    if (col.col_md_offset) {
        classes.push('offset-md-' + col.col_md_offset);
    }

    if (col.col_lg) {
        classes.push('col-lg-' + col.col_lg);
    }

    if (col.col_lg_offset) {
        classes.push('offset-lg-' + col.col_lg_offset);
    }

    if (col.col_xl) {
        classes.push('col-xl-' + col.col_xl);
    }

    if (col.col_xl_offset) {
        classes.push('offset-xl-' + col.col_xl_offset);
    }

    if (classes.length > 0) {
        return classes.join(' ');
    }

    return 'col-3';
}

export function findReplaceString(string, replace)
{
    if ((/[a-zA-Z\_]+/g).test(string)) {
        return string.replace(new RegExp('\{\{(?:\\s+)?(.+?)(?:\\s+)?\}\}'), replace);
    } else {
        throw new Error("Find statement does not match regular expression: /[a-zA-Z\_]+/");
    }
}

export function userCan(userRoles, checkRole) {
    if (typeof checkRole === 'string') {
        return userRoles.includes(checkRole);
    } else if (Array.isArray(checkRole)) {
        for (let role of checkRole) {
            if (userRoles.includes(role)) {
                return true;
            }
        }
        return false;
    }
}

export function getBadgeType(type) {
    let badgeTypes = {
        'badge badge-success': 'badge bg-success',
        'badge badge-warning': 'badge bg-warning',
        'badge badge-danger': 'badge bg-danger',
        'badge badge-primary': 'badge bg-primary',
        'badge badge-info': 'badge bg-info',
        'badge badge-secondary': 'badge bg-secondary',
        'badge badge-light': 'badge bg-light',
        'badge badge-dark': 'badge bg-dark',
    }

    return badgeTypes[type] ?? 'badge bg-secondary';
}

export function formatDateRange(dateArray) {
    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('ru-RU').split('/').join('.');
    };

    if (dateArray.length !== 2) {
        throw new Error('Array must contain exactly two dates');
    }

    return `${formatDate(dateArray[0])} - ${formatDate(dateArray[1])}`;
}
