import React, {useState} from "react";
import {TOKEN} from "../../utils/consts";
import {bindActionCreators} from "redux";
import {connect, useDispatch} from "react-redux";
import {updateToken} from "../../storage/actions/UserActions";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form"
import {axiosInstanceWithoutToken} from "../../utils/apiCalls";


function LoginScreen() {
    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
    } = useForm({
        defaultValues: {
            username: "",
            password: "",
        },
    })


    const [isLoad, setIsLoad] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const auth = (data) => {
        setIsLoad(true);
        axiosInstanceWithoutToken.get('/users/token', { auth: data })
            .then(response => response.data)
            .then(responseJson => {
                const credentials = {
                    id: responseJson.data.token.user_id,
                    token: responseJson.data.token.code,
                    username: responseJson.data.username,
                    roles: responseJson.data.roles,
                };
                dispatch(updateToken(credentials));
                localStorage.setItem(TOKEN, JSON.stringify(credentials));
                window.location.href = '/';
            })
            .catch(error => {
                setError('password', { type: 'custom', message: error?.response?.data?.errors?.message });
            })
            .finally(() => setIsLoad(false));
    };

    return (
        <div className={"row"}>
            <div
                className={"col col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"}>
                <br/>
                <br/>
                <div className={"card"}>
                    <div className={"card-body table-responsive"}>
                        <div className={"card-title d-flex align-items-center mb-3"}><h5>Авторизация</h5></div>
                        <form onSubmit={handleSubmit(auth)}>
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Логин</label>
                                <input id="username" {...register("username", {
                                    required: 'Username is required!',
                                    maxLength: 50,
                                    minLength: 3
                                })}
                                       className={"form-control " + (errors.username ? "is-invalid" : '')}/>
                                {errors.username && (
                                    <div className="invalid-feedback">
                                        {errors.username.message}
                                    </div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Пароль</label>
                                <input id="password" {...register("password", {
                                    required: 'Password is required!',
                                    maxLength: 50
                                })}
                                       className={"form-control " + (errors.password ? "is-invalid" : '')}
                                       type={'password'}/>
                                {errors.password && (
                                    <div className="invalid-feedback">
                                        {errors.password.message}
                                    </div>
                                )}
                            </div>
                            {!isLoad && <button type="submit" className="btn btn-primary">Войти</button>}
                            {isLoad && <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
