import React from "react";
import {Outlet, NavLink, useNavigate} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect, useDispatch} from "react-redux";
import {TOKEN} from "../../utils/consts";
import {updateToken} from "../../storage/actions/UserActions";
import {userCan} from "../../utils/helpers";

function Layout({user}) {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem(TOKEN);
        dispatch(updateToken({id: 0, token: null}));

        navigate('/login')
    }

    return (
        <>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">CRMAuto</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav me-auto mb-2 mb-md-0">
                            {/*<li className="nav-item">*/}
                            {/*    <NavLink to={'/'} className={({isActive}) => {*/}
                            {/*        return 'nav-link ' + (isActive ? 'active' : '')*/}
                            {/*    }} aria-current="page">Главная страница</NavLink>*/}
                            {/*</li>*/}
                            {user.id && <li className="nav-item">
                                <NavLink to={'/deals'} className={({isActive}) => {
                                    return 'nav-link ' + (isActive ? 'active' : '')
                                }} aria-current="page">Список сделок</NavLink>
                            </li>}

                            {userCan(user.roles, ['admin']) && (<li className="nav-item">
                                <NavLink to={'/statistic'} className={({isActive}) => {
                                    return 'nav-link ' + (isActive ? 'active' : '')
                                }} aria-current="page">Статистика</NavLink>
                            </li>)}
                            {userCan(user.roles, 'admin') && (<li className="nav-item">
                                <NavLink to={'/organization'} className={({isActive}) => {
                                    return 'nav-link ' + (isActive ? 'active' : '')
                                }} aria-current="page">Подразделения</NavLink>
                            </li>)}
                            {userCan(user.roles, 'admin') && (<li className="nav-item">
                                <NavLink to={'/projects'} className={({isActive}) => {
                                    return 'nav-link ' + (isActive ? 'active' : '')
                                }} aria-current="page">Проекты</NavLink>
                            </li>)}
                            {userCan(user.roles, 'admin') && (<li className="nav-item">
                                <NavLink to={'/product'} className={({isActive}) => {
                                    return 'nav-link ' + (isActive ? 'active' : '')
                                }} aria-current="page">Продукты</NavLink>
                            </li>)}
                        </ul>
                        <ul className="navbar-nav d-flex mb-2 mb-md-0">
                            {!user.id && <li className="nav-item">
                                <NavLink to={'/login'} className={({isActive}) => {
                                    return 'nav-link ' + (isActive ? 'active' : '')
                                }} aria-current="page">Войти</NavLink>
                            </li>}
                            {user.id && <li className="nav-item">
                                <NavLink to={'/profile'} className={'nav-link'} aria-current="page">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="6" r="4" stroke="white" strokeWidth="1.5"/>
                                        <path d="M19.9975 18C20 17.8358 20 17.669 20 17.5C20 15.0147 16.4183 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C14.231 22 15.8398 21.8433 17 21.5634" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                    </svg>
                                    {user.username}
                                </NavLink>
                            </li>}
                            {user.id && <li className="nav-item">
                                <a onClick={logout} href='#logout' className={'nav-link'} aria-current="page">Выйти</a>
                            </li>}
                        </ul>
                    </div>
                </div>
            </nav>
            <main className="container-fluid">
                <Outlet/>
            </main>
        </>
    );
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
